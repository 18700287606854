import React, { FC, useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Grid,
  Button,
  Modal,
  Box,
  InputBase,
  Checkbox,
  FormGroup,
  FormControlLabel,
  TextField,
  Alert,
  Snackbar,
  Skeleton,
} from "@mui/material";
import DataTable from "./../DataTable/DataTable";
import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { GridColDef } from "@mui/x-data-grid";
import {
  formatCarneResgatado,
  formatarValorEmReais,
  validateName,
  formatCpf,
  validateCpf,
  validateCreditCard,
  isValidCreditCardDate,
  validateCVV,
} from "../../utils/utils";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useIsMobile } from "../../utils/utils";
import { CartContext } from "../../context/CartProvider";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useHandleParcela } from "../../context/ContextParcelas/userContextParcelas";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ErroSemResultados from "../ErroSemResultados";
import { ContentPasteSearchOutlined } from "@mui/icons-material";
import { CustomTooltip } from "./Parcelas.styled";

const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiTypography-root .MuiFormLabel-root .MuiInputLabel-root": {
    fontFamily: "Red Hat Display, sans-serif",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "2px solid #BDBDBD",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    marginBottom: theme.spacing(2),
    heigth: "30px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ["Red Hat Display, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#F08C10",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

type Props = {
  menu: string;
  setView: any;
  setId: any;
  setCode: any;
  onClickDetalhes: () => void;
};

const MeusCarnes: FC<Props> = ({
  menu,
  setView,
  setId,
  setCode,
  onClickDetalhes,
}) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { setMenuSidebar } = useHandleParcela();
  const auth = useAuth();
  const [dataId, setDataId] = useState("");
  const [dataPage, setDataPage] = useState("");
  const [listCarnes, setListCarnes] = useState<null | any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [openModalMobile, setOpenModalMobile] = useState(false);
  const [modalRemove, setModalRemove] = useState(false);
  const [carNumber, setCardNumber] = useState("");
  const [numberSerie, setNumberSerie] = useState("");
  const [date, setDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [fullName, setFullName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [isError, setIsError] = useState(false);
  const [cpf, setCpf] = useState("");
  const [cpfModal, setCpfModal] = useState("");
  const [user, setUser] = useState("");
  const [userValue, setUserValue] = useState<null | any>(null);
  const [check, setCheck] = useState(false);
  const [errorCpf, setErrorCpf] = useState(false);
  const [errorCard, setErrorCard] = useState(false);
  const [errorCvv, setErrorCvv] = useState(false);
  const [errorDateCard, setErrorDateCard] = useState(false);
  const { payment, setPayment } = useContext(CartContext)!;
  const [isButtonEnabled, setButtonEnabled] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertStatus, setAlertStatus] = useState<"success" | "error" | null>(
    null
  );
  const { handleSideBar, toggleSubMenu } = useHandleSideBar();
  const { setState } = useHandleParcela();
  const [validate, setValidate] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const tokenBko = localStorage.getItem("backOfficeId");
  const token = localStorage.getItem("token");
  const [isErrorCarne, setIsErroCarne] = useState(false);
  const { handleDrawerClose } = useHandleSideBar();

  const handleDetalhesClick = () => {
    onClickDetalhes();
  };
  useEffect(() => {
    if (tokenBko) setValidate(true);
  }, [tokenBko]);

  useEffect(() => {
    const areAllFieldsFilled = () => {
      return (
        carNumber !== "" &&
        date !== "" &&
        cvv !== "" &&
        fullName !== "" &&
        (check ? cpfModal : cpf) !== "" &&
        errorCard === false &&
        errorDateCard === false &&
        isError === false &&
        errorCpf === false
      );
    };

    setButtonEnabled(areAllFieldsFilled());
  }, [
    carNumber,
    date,
    cvv,
    fullName,
    cpf,
    cpfModal,
    check,
    errorCard,
    errorDateCard,
    isError,
    errorCpf,
  ]);

  useEffect(() => {
    if (token) {
      setDataId(token);
      handleDescript(token, menu);
    }

    if (menu) {
      setDataPage(menu);
    }
  }, [menu]);

  const renderTypography = (headerName: any) => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Typography
        component="p"
        variant="subtitle1"
        fontWeight={700}
        color="#000000"
        sx={{
          fontSize: "10px",
          textTransform: "uppercase",
          lineHeight: "13px",
        }}
      >
        {headerName}
      </Typography>
    </div>
  );

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Série | Número-DV",
      width: 150,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "tipo",
      headerName: "Tipo",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "valorDisponivel",
      headerName: "Valor Disponível",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
    },
    {
      field: "recorencia",
      headerName: "Pagar em Recorrência",
      width: 100,
      sortable: false,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      disableColumnMenu: true,
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isShowSliceRecorrencia(params.row) && (
            <AntSwitch
              id={params.row.key}
              checked={params.row.possuiAssinatura}
              onChange={(event) =>
                handleSwitch(
                  event,
                  params.row.codigoBarras,
                  params.row.possuiAssinatura
                )
              }
              inputProps={{ "aria-label": "ant design" }}
            />
          )}
        </div>
      ),
    },
    {
      field: "consulta",
      headerName: `Consulta Parcelas`,
      headerClassName: "header-name",
      width: 100,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      renderCell: (params) => (
        <button
          onClick={() => {
            handlePagamento(params.row, "Detalhes");
            setState(true);
          }}
          style={{
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 700,
            textDecoration: "underline",
            background: "transparent",
            color: "#1C1C7B",
            border: "none",
          }}
        >
          Detalhes
        </button>
      ),
    },
    {
      field: "button",
      headerName: "Ação",
      width: 125,
      sortable: false,
      disableColumnMenu: true,
      renderHeader: (params) => renderTypography(params.colDef.headerName),
      renderCell: (params) =>
        params.row.status === "Resgate Iniciado"
          ? handleToolTipResgatar(params)
          : handleGrid(params),
    },
  ];

  const handleToolTipResgatar = (params: any) => {
    return (
      <CustomTooltip
        placement="left"
        title={
          <>
            <Typography
              style={{
                display: "flex",
                textAlign: "center",
                margin: "auto",
                justifyContent: "center",
                paddingBottom: 10,
                color: "#000",
                fontWeight: 700,
              }}
              fontSize={16}
            >
              Atenção!
            </Typography>
            <p
              style={{
                fontSize: 14,
                alignItems: "center",
              }}
            >
              <strong>Seu Resgate foi iniciado!</strong> Caso o mesmo não seja
              concluído, <strong>aguarde 30 minutos</strong> que ele voltará a
              ser disponibilizado. Em caso de dúvida ou ajuda, entre em contato
              conosco através da nossa central de atendimento
              <strong> 0800 545 2121</strong>
              {/* <img
                src={`${process.env.REACT_APP_IMAGE_SERVER_URL}icon-whatzapp.svg`}
              />
              <a
                rel="noopener noreferrer"
                style={{ color: "#25D366" }}
                href="https://api.whatsapp.com/send?phone=08005452121"
                target="_blank"
              >
                WhatsApp
              </a> */}
            </p>
          </>
        }
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={true}
            onClick={() => {}}
            sx={{
              width: "110px",
              height: "37px",
              border: `1px solid ${
                !enableButtonAction(params.row, dataPage)
                  ? "#BDBDBD"
                  : "#F08C10"
              }`,
              borderRadius: 0,
              display: "block",
              background: "#FFFFFF",
              "&:hover": {
                background: "#FFFFFF",
              },
            }}
          >
            <Typography
              component="p"
              color={`${
                !enableButtonAction(params.row, dataPage)
                  ? "#BDBDBD"
                  : "#F08C10"
              }`}
              variant="subtitle2"
              sx={{
                fontSize: "10px",
                lineHeight: "14px",
                fontWeight: 700,
              }}
              dangerouslySetInnerHTML={{
                __html: "Resgatando...",
              }}
            />
          </Button>
        </div>
      </CustomTooltip>
    );
  };

  const handleGrid = (params: any) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          disabled={!enableButtonAction(params.row, dataPage)}
          onClick={() => {
            params.row.status === "Quitado" ||
            params.row.status === "Resgate Iniciado" ||
            params.row.status === "Resgatado"
              ? handlePagamento(params.row, "Magento")
              : handlePagamento(params.row, "Detalhes");
          }}
          sx={{
            width: "110px",
            height: "37px",
            border: `1px solid ${
              !enableButtonAction(params.row, dataPage) ? "#BDBDBD" : "#F08C10"
            }`,
            borderRadius: 0,
            display:
              params.row.verMeusPedidos ||
              params.row.aceitaPagamento ||
              params.row.status === "Quitado" ||
              !params.row.semAcao
                ? "block"
                : "none",
            background: "#FFFFFF",
            "&:hover": {
              background: "#FFFFFF",
            },
          }}
        >
          <Typography
            component="p"
            color={`${
              !enableButtonAction(params.row, dataPage) ? "#BDBDBD" : "#F08C10"
            }`}
            variant="subtitle2"
            sx={{
              fontSize: "10px",
              lineHeight: "14px",
              fontWeight: 700,
            }}
            dangerouslySetInnerHTML={{
              __html: params.row.verMeusPedidos
                ? "Ver Pedido"
                : params.row.resgatar &&
                  (menu === "resgate" || menu === "consulta")
                ? "Resgatar"
                : (menu === "pagar" &&
                    params.row.aceitaPagamento &&
                    params.row.status === "Quitado") ||
                  params.row.aceitaPagamento ||
                  (params.row.pagarParcela && !params.row.resgatar)
                ? "Pagar Parcela"
                : params.row.semAcao || params.row.status === ""
                ? ""
                : "",
            }}
          />
        </Button>
      </div>
    );
  };

  const isShowSliceRecorrencia = (item: any): boolean => {
    return (
      (item.quantidadeParcelasPagas < 11 && item.aceitaPagamento) ||
      item.possuiAssinatura
    );
  };

  const enableButtonAction = (item: any, detailType: any): boolean => {
    let isPagarParcela = !(
      item.status === "Quitado" || item.status === "Resgate Iniciado"
    );

    if (item.verMeusPedidos) return item.urlVerMeusPedidos;

    if (isPagarParcela)
      return (
        item.aceitaPagamento &&
        item.pagarParcela &&
        item.status !== "Resgate Iniciado" &&
        item.status !== "Sem Vinculo" &&
        !item.possuiAssinatura
      );
    else return item.status === "Quitado";
  };

  const handleSetPayment = (data: boolean, item: any) => {
    setPayment(data);
    handleParcela(item.codigoBarras);
  };

  const handleDescript = async (data: string, page: string) => {
    try {
      if (auth) {
        const request = await auth?.descriptToken(data);
        setUserValue(request);
        handleCarnes(request, page);
      }
    } catch (error: any) {
      throw error;
    }
  };

  const handleCarnes = async (data: any, page: string) => {
    setLoading(true);
    try {
      if (page === "consulta") {
        const response = await auth?.carneList(data.login);

        if (Array.isArray(response)) {
          setLoading(false);
          setUser(data.login);

          let uniqueResponse: any[] = [];
          response.map((item: any) => {
            var findItem = uniqueResponse.find(
              (itemResponse: any) =>
                itemResponse.serie === item.serie &&
                itemResponse.numero === item.numero &&
                itemResponse.digito === item.digito
            );
            if (!findItem) uniqueResponse.push(item);
          });

          setListCarnes(uniqueResponse);
        } else {
          setLoading(false);
          setListCarnes([]);
        }
      }

      if (page === "resgate") {
        const response = await auth?.carneList(data.login);

        if (Array.isArray(response)) {
          setLoading(false);
          setUser(data.login);

          let uniqueResponse: any[] = [];
          response.map((item: any) => {
            var findItem = uniqueResponse.find(
              (itemResponse: any) =>
                itemResponse.serie === item.serie &&
                itemResponse.numero === item.numero &&
                itemResponse.digito === item.digito
            );
            if (!findItem) uniqueResponse.push(item);
          });
          const filteredCarnes = uniqueResponse.filter(
            (carne) => carne.resgatar || carne.carneQuitado
          );
          setListCarnes(filteredCarnes);
        } else {
          setLoading(false);
          setListCarnes([]);
        }
      }

      if (page === "pagar") {
        const response = await auth?.carneList(data.login);

        if (Array.isArray(response)) {
          setLoading(false);
          setUser(data.login);

          let uniqueResponse: any[] = [];
          response.map((item: any) => {
            var findItem = uniqueResponse.find(
              (itemResponse: any) =>
                itemResponse.serie === item.serie &&
                itemResponse.numero === item.numero &&
                itemResponse.digito === item.digito
            );
            if (!findItem) uniqueResponse.push(item);
          });
          const filteredCarnes = uniqueResponse.filter(
            (carne) => carne.pagarParcela
          );
          setListCarnes(filteredCarnes);
        } else {
          setLoading(false);
          setListCarnes([]);
        }
      }
    } catch (error: any) {
      setLoading(false);
      setIsErroCarne(true);
      throw error;
    }
  };

  const handleCard = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "$1 $2 $3 $4")
      .slice(0, 19);
    setCardNumber(value);
    setErrorCard(!validateCreditCard(event.target.value));
  };

  const handledate = (event: any) => {
    const value = event.target.value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d{4})/, "$1/$2")
      .slice(0, 7);
    setDate(value);
    setErrorDateCard(!isValidCreditCardDate(value));
  };

  const handleCvv = (event: any) => {
    const value = event.target.value.replace(/\D/g, "").slice(0, 3);
    setCvv(value);
    setErrorCvv(value.length === 3 ? false : !validateCVV(value));
  };

  const handleInputName = (event: any) => {
    const value = event.target.value.replace(/[0-9]/g, "");
    const values = event.target.value.trim();
    const [firstName, ...rest] = values.split(" ");
    const secondName = rest.join(" ");
    setFirstName(firstName);
    setSecondName(secondName);
    setFullName(value.slice(0, 50));
    setCheck(false);
    setIsError(!validateName(event.target.value));
  };

  const handleChangeCpf = (event: any) => {
    const value = event.target.value;
    setCpf(value);
    setCheck(false);
    setErrorCpf(!validateCpf(event.target.value) || !event.target.value);
  };

  const handleSwitch = async (
    event: React.ChangeEvent<HTMLInputElement>,
    item: string,
    assinatura: boolean
  ) => {
    const value = event.target.checked;

    if (assinatura) {
      setNumberSerie(item);
      setModalRemove(true);
    } else {
      setNumberSerie(item);
      isMobile ? setOpenModalMobile(true) : handleOpenModal();
    }
  };

  const handleCheck = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked;

    setCheck(value);

    if (value) {
      setCpfModal(userValue.login);
      setFullName(userValue.nome + " " + userValue.sobrenome);
      setIsError(false);
      setErrorCpf(!validateCpf(userValue.login) || !userValue.login);
    } else {
      setCpfModal("");
      setFullName("");
    }
  };
  const handleRecorrencia = async () => {
    const dateValue = date;
    const mes = dateValue.substring(0, 2);
    const ano = dateValue.substring(3, 7);
    const numberCard = carNumber.replace(/\s/g, "");
    const data = {
      numeroCarne: numberSerie,
      assinatura: true,
      cartaoCredito: {
        nomeTitularCartao: fullName,
        numeroCartao: numberCard,
        mesVencimento: mes,
        anoVencimento: ano,
        codigoSeguranca: cvv,
      },
    };

    try {
      if (auth) {
        const request = await auth?.recorrencia(data, dataId);

        setAlertMessage(JSON.stringify(request));
        setAlertStatus("success");
        setAlertOpen(true);
        handleCloseModal();
        handleDescript(dataId, dataPage);
      }
    } catch (error: any) {
      setAlertMessage(JSON.stringify(error));
      setAlertStatus("error");
      setAlertOpen(true);
      throw error;
    }
  };

  const handleDeleteRecorrencia = async () => {
    try {
      if (auth) {
        const request = await auth?.recorrenciaDelete(numberSerie, dataId);

        setAlertMessage(JSON.stringify(request));
        setAlertStatus("success");
        setAlertOpen(true);
        setModalRemove(false);
        handleDescript(dataId, dataPage);
      }
    } catch (error: any) {
      setAlertMessage(JSON.stringify(error.message));
      setAlertStatus("error");
      setAlertOpen(true);
      setModalRemove(false);
      throw error;
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    isMobile ? setOpenModalMobile(false) : setOpenModal(false);
    setCpf("");
    setFullName("");
    setCardNumber("");
    setDate("");
    setCvv("");
    setCheck(false);
    setNumberSerie("");
  };

  const handlePagamento = async (item: any, type: string) => {
    setState(true);
    if (type === "Magento") {
      if (item.resgatar && (menu === "resgate" || menu === "consulta")) {
        const data = {
          codigoBarras: item.codigoBarras,
          cpf: user,
        };
        try {
          if (auth) {
            const response = await auth?.resgateUrl(data);

            if (token) {
              handleDescript(token, "resgate");
            }

            setAlertMessage("Você será direcionado para nosso site de Resgate");
            setAlertStatus("success");
            setAlertOpen(true);
            window.open(`${response}`, "_blank");
          }
        } catch (error: any) {
          throw error;
        }
      }
      return;
    }

    handleParcela(item.codigoBarras);
  };

  // const handlePagamento = async (item: any, type: string) => {
  //   setState(true);
  //   if (type === "Magento") {
  //     if (item.resgatar && (menu === "resgate" || menu === "consulta")) {
  //       return (window.location.href = item.urlResgatar);
  //     }
  //     if (item.verMeusPedidos) {
  //       return (window.location.href = item.urlVerMeusPedidos);
  //     }
  //   }
  //   handleParcela(item.codigoBarras);
  // };

  const handleParcela = (item: string) => {
    setId(dataId);
    setCode(item);
    setView(true);
  };

  const handleClick = () => {
    validate ? navigate("/autenticar/minha-conta") : navigate("/minha-conta");
    toggleSubMenu(true);
    handleSideBar("Cadastrar Carnê");
  };

  const rows: any[] = listCarnes?.map((row: any, index: any) => ({
    id: formatCarneResgatado(row.serie + row.numero + row.digito),
    key: index,
    tipo: row.tipoCarne,
    valorDisponivel: formatarValorEmReais(
      parseInt(row.valorResgate + row.valorCredito)
    ),
    status: row.status,
    codigoBarras: row.codigoBarras,
    quantidadeParcelasPagas: row.quantidadeParcelasPagas,
    possuiAssinatura: row.possuiAssinatura,
    aceitaPagamento: row.aceitaPagamento,
    verMeusPedidos: row.verMeusPedidos,
    semAcao: row.semAcao,
    pagarParcela: row.pagarParcela,
    resgatar: row.resgatar,
    urlVerMeusPedidos: row.urlVerMeusPedidos,
    urlResgatar: row.urlResgatar,
  }));

  return (
    <>
      <Grid container sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          lg={12}
          md={12}
          sm={10}
          xs={10}
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "100px",
          }}
        >
          <Modal
            disableEnforceFocus
            disableAutoFocus
            open={modalRemove}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 300,
                height: 350,
                backgroundColor: "#FFFFFF",
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 700,
                  margin: "20px 27px 17px 27px",
                }}
              >
                Remover pagamento em recorrência no cartão
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 400,
                  margin: "0 27px 17px 27px",
                }}
              >
                Deseja remover o pagamento mensal de suas parcelas em seu cartão
                de crédito?
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 400,
                  margin: "0 27px 17px 27px",
                }}
              >
                Atenção: após a remoção, as parcelas não serão mais pagas
                automaticamente a cada mês.
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                color="#000000"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "12px",
                  lineHeight: "20px",
                  textAlign: "center",
                  fontWeight: 400,
                  margin: "0 27px 17px 27px",
                }}
              >
                Deseja confirmar o cancelamento da assinatura desse carnê?
              </Typography>
              <div
                style={{
                  margin: "0 27px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => setModalRemove(false)}
                  sx={{
                    width: 104,
                    height: 33,
                    fontSize: "12px",
                    background: "#FFFFFF",
                    color: "#F08C10",
                    border: "1px solid #F08C10",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleDeleteRecorrencia}
                  sx={{
                    width: 104,
                    height: 33,
                    fontSize: "12px",
                    background: "#F08C10",
                    color: "#FFFFFF",
                    "&:hover": {
                      background: "#F08C10",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Confirmar
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            disableEnforceFocus
            disableAutoFocus
            open={openModalMobile}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 288,
                height: 600,
                backgroundColor: "#FFFFFF",
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "16px",
                  lineHeight: "21px",
                  margin: "19px 0 10px 0",
                  color: "#000000",
                }}
              >
                Pagamento com Cartão
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "10px",
                  lineHeight: "13px",
                  marginBottom: "15px",
                  color: "#000000",
                }}
              >
                Você optou por pagamento recorrente no cartão de crédito.
              </Typography>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <TextField
                  value={carNumber}
                  onChange={handleCard}
                  name="Number Card"
                  placeholder="Número do cartão"
                  sx={{
                    width: 288,
                    height: 45,
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  required
                  error={errorCard}
                  helperText={
                    errorCard
                      ? "Por favor, insira um número de cartão válido"
                      : ""
                  }
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "30px",
                }}
              >
                <TextField
                  name="Ano"
                  placeholder="MM/AAAA"
                  value={date}
                  onChange={handledate}
                  sx={{
                    width: 160,
                    height: 48,
                    margin: "0 15px 0 0",
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                  required
                  error={errorDateCard}
                  helperText={errorDateCard ? "Informe uma data válida" : ""}
                />
                <TextField
                  name="CVV"
                  placeholder="CVV"
                  value={cvv}
                  onChange={handleCvv}
                  error={errorCvv}
                  helperText={
                    errorCvv ? "Por favor, insira um CVV válido." : ""
                  }
                  sx={{
                    width: 140,
                    height: 48,
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={check}
                        onChange={handleCheck}
                        sx={{
                          color: "#F08C10",
                          "&.Mui-checked": {
                            color: "#F08C10",
                          },
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant="subtitle2"
                        color="#000000"
                        sx={{
                          lineHeigth: 20,
                        }}
                      >
                        Usar os dados do cadastro
                      </Typography>
                    }
                  />
                </FormGroup>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <TextField
                  placeholder="Nome impresso no cartão"
                  type="text"
                  name="name"
                  value={fullName}
                  onChange={handleInputName}
                  required
                  error={isError}
                  helperText={
                    isError ? "Nome e sobrenome são obrigatórios" : ""
                  }
                  sx={{
                    width: 288,
                    height: 45,
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <TextField
                  name="number"
                  placeholder="CPF do titular do cartão"
                  value={formatCpf(check ? cpfModal : cpf)}
                  onChange={handleChangeCpf}
                  error={errorCpf}
                  helperText={errorCpf ? "CPF inválido" : null}
                  required
                  sx={{
                    width: 288,
                    height: 45,
                  }}
                  InputProps={{
                    style: {
                      fontSize: "14px",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#F08C10"
                  sx={{
                    fontWeight: 700,
                    lineHeight: "20px",
                    fontSize: "12px",
                  }}
                >
                  PAGAMENTO RECORRENTE
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  marginBottom: "15px",
                }}
              >
                <Typography
                  color="#000000"
                  variant="subtitle2"
                  sx={{
                    lineHeight: "20px",
                    fontSize: "12px",
                    fontWeight: 700,
                  }}
                >
                  Ao finalizar o pagamento, você autoriza o débito mensal dos
                  próximos pagamentos diretamente no cartão de crédito
                  informado.
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={handleCloseModal}
                  sx={{
                    width: 104,
                    height: 33,
                    fontSize: "12px",
                    lineHeight: "10px",
                    background: "#FFFFFF",
                    color: "#F08C10",
                    border: "1px solid #F08C10",
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  onClick={handleRecorrencia}
                  sx={{
                    width: 104,
                    height: 33,
                    fontSize: "12px",
                    lineHeight: "10px",
                    background: "#F08C10",
                    color: "#FFFFFF",
                    "&:hover": {
                      background: "#F08C10",
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Confirmar
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal
            disableEnforceFocus
            disableAutoFocus
            open={openModal}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: 862,
                height: 588,
                backgroundColor: "#FFFFFF",
                boxShadow: 24,
                p: 1,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "32px",
                  lineHeight: "42px",
                  margin: "47px 0 10px 0",
                  color: "#001C47",
                }}
              >
                Pagamento com Cartão
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  lineHeight: "20px",
                  marginBottom: "52px",
                  color: "#001C47",
                }}
              >
                Você optou por pagamento recorrente no cartão de crédito.
              </Typography>
              <form>
                <div
                  style={{
                    margin: "0 100px 31px 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    value={carNumber}
                    onChange={handleCard}
                    name="Number Card"
                    placeholder="Número do cartão"
                    sx={{
                      width: 308,
                      height: 48,
                      margin: "0 25px 0 0",
                    }}
                    required
                    error={errorCard}
                    helperText={
                      errorCard
                        ? "Por favor, insira um número de cartão válido"
                        : ""
                    }
                  />
                  <TextField
                    name="Ano"
                    placeholder="MM/AAAA"
                    value={date}
                    onChange={handledate}
                    sx={{
                      width: 160,
                      height: 48,
                      margin: "0 15px 0 0",
                    }}
                    required
                    error={errorDateCard}
                    helperText={errorDateCard ? "Informe uma data válida" : ""}
                  />
                  <TextField
                    name="CVV"
                    placeholder="CVV"
                    value={cvv}
                    onChange={handleCvv}
                    error={errorCvv}
                    helperText={
                      errorCvv ? "Por favor, insira um CVV válido." : ""
                    }
                    sx={{
                      width: 140,
                      height: 48,
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: "0 100px 0 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={check}
                          onChange={handleCheck}
                          sx={{
                            color: "#F08C10",
                            "&.Mui-checked": {
                              color: "#F08C10",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          color="#000000"
                          sx={{
                            lineHeigth: 20,
                          }}
                        >
                          Usar os dados do cadastro
                        </Typography>
                      }
                    />
                  </FormGroup>
                </div>
                <div
                  style={{
                    margin: "0 100px 43px 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <TextField
                    placeholder="Nome impresso no cartão"
                    type="text"
                    name="name"
                    value={fullName}
                    onChange={handleInputName}
                    required
                    error={isError}
                    helperText={
                      isError ? "Nome e sobrenome são obrigatórios" : ""
                    }
                    sx={{
                      width: 308,
                      height: 48,
                      margin: "0 25px 0 0",
                    }}
                  />
                  <TextField
                    name="number"
                    placeholder="CPF do titular do cartão"
                    value={formatCpf(check ? cpfModal : cpf)}
                    onChange={handleChangeCpf}
                    error={errorCpf}
                    helperText={errorCpf ? "CPF inválido" : null}
                    required
                    sx={{
                      width: 313,
                      height: 48,
                    }}
                  />
                </div>
                <div
                  style={{
                    margin: "0 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="#F08C10"
                    sx={{
                      fontWeight: 700,
                      lineHeight: "20px",
                    }}
                  >
                    PAGAMENTO RECORRENTE
                  </Typography>
                </div>
                <div
                  style={{
                    margin: "0 100px 43px 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    color="#000000"
                    variant="subtitle2"
                    sx={{
                      lineHeight: "20px",
                      fontWeight: 700,
                    }}
                  >
                    Ao finalizar o pagamento, você autoriza o débito mensal dos
                    próximos pagamentos diretamente no cartão de crédito
                    informado.
                  </Typography>
                </div>
                <div
                  style={{
                    margin: "0 100px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    onClick={handleCloseModal}
                    sx={{
                      width: 308,
                      height: 48,
                      fontSize: "14px",
                      background: "#FFFFFF",
                      color: "#F08C10",
                      border: "1px solid #F08C10",
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={handleRecorrencia}
                    disabled={!isButtonEnabled}
                    sx={{
                      width: 313,
                      height: 48,
                      fontSize: "14px",
                      background: `${!isButtonEnabled ? "#BDBDBD" : "#F08C10"}`,
                      color: "#FFFFFF",
                      "&:hover": {
                        background: "#F08C10",
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Confirmar
                  </Button>
                </div>
              </form>
            </Box>
          </Modal>
          <Grid container sx={{ display: "flex", justifyContent: "center" }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <div>
                <Typography
                  variant="h1"
                  sx={{ margin: "30px 0px", width: "max-content" }}
                >
                  {isMobile && (
                    <ArrowBackIosIcon
                      onClick={() => setMenuSidebar(false)}
                      sx={{ fontSize: "32px" }}
                    />
                  )}
                  {`${
                    dataPage === "pagar"
                      ? "Pagar Parcela"
                      : dataPage === "resgate"
                      ? "Resgatar Produtos"
                      : dataPage === "consulta"
                      ? "Consultar Carnês"
                      : ""
                  }`}
                </Typography>
              </div>
            </Grid>
            {rows && rows.length > 0 ? (
              <>
                <Typography
                  variant="subtitle1"
                  color="#000000"
                  sx={{ margin: "0 15px 30px 15px" }}
                >
                  <b>Acompanhe</b> seus carnês, acessando os detalhes. Caso
                  queira <b>Cadastrar um novo carnê</b>,{" "}
                  <Typography
                    variant="subtitle1"
                    component="a"
                    color="#F08C10"
                    onClick={() => handleClick()}
                    sx={{
                      fontWeight: 700,
                      cursor: "pointer",
                      "&: hover": {
                        color: "#F08C10",
                      },
                    }}
                  >
                    clique aqui.
                  </Typography>{" "}
                  <b>Pague</b> seu carnê em recorrência no cartão de crédito, ou
                  realize o pagamento individual de suas parcelas.
                </Typography>

                <DataTable rows={rows} columns={columns} />
              </>
            ) : loading ? (
              <Skeleton variant="rectangular" width={775} height={547} />
            ) : (rows && rows.length === 0) || isErrorCarne ? (
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "inline-table",
                  width: "100%",
                }}
              >
                <ErroSemResultados />
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
      >
        <Alert
          variant="filled"
          onClose={() => setAlertOpen(false)}
          severity={alertStatus || "info"}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MeusCarnes;
