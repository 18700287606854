import React, { useEffect } from "react";
import MinhaConta from "../../pages/MinhaConta";
import { DataTableCarnes } from "../MainContent";
import { useSearchParams } from 'react-router-dom';
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

import {
  Descript,
} from "../../context/AuthProvider/util";
import { api } from "../../services/api";

const Logoff = () => 
{
  const { activeSection, setActiveMenu, handleSideBar } = useHandleSideBar();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    localStorage.removeItem("token");
    localStorage.removeItem("tokenId");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("token_system");
    localStorage.removeItem("bannerUserClose");
    localStorage.removeItem("selectedItems");
    localStorage.removeItem("dataClient");
    window.open("/login", "_self");
  }, []);


  return null;  
};

export default Logoff;
