import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Typography, Snackbar } from "@mui/material";
import { Container } from "./CookieModal.styled";
import ContainerBox from "../ContainerBox";
import { useNavigate } from "react-router-dom";

const CookieModal: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const cookieExists = document.cookie
      .split(";")
      .some((item) => item.trim().startsWith("aceiteCookie="));
    if (!cookieExists) {
      setShowModal(true);
    }
  }, []);

  const handleClose = () => {
    setCookie("aceiteCookie", "true");
    setShowModal(false);
  };

  const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value}; path=/`;
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClick = () => {
    const id = "3";
    navigate("/politicas-legais", { state: { id } });
    scrollToTop();
  };

  return (
    <ContainerBox>
      {showModal && (
        <Snackbar
          sx={{
            width: { xs: "300px", sm: "718px", md: "869px", lg: "1129px" },
            margin: "auto",
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={true}
        >
          <Container>
            <div>
              <Typography
                variant="body1"
                className="content-text"
                sx={{
                  fontWeight: 400,
                  fontSize: 15,
                  color: "#000",
                }}
              >
                Utilizamos tecnologias de acordo com nossa política de
                privacidade, incluindo cookies. Ao permanecer navegando, você
                concorda com estas condições.{" "}
                <Typography
                  component="a"
                  variant="body1"
                  onClick={handleClick}
                  color={"#000"}
                  style={{ cursor: "pointer", fontWeight: 400, fontSize: 15 }}
                >
                  Acesse aqui nossa política de privacidade.
                </Typography>
              </Typography>
            </div>
            <div>
              <Button onClick={handleClose} className="btn-ok">
                Entendi
              </Button>
            </div>
          </Container>
        </Snackbar>
      )}
    </ContainerBox>
  );
};

export default CookieModal;
