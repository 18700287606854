import React, { useEffect } from "react";
import MinhaConta from "../../pages/MinhaConta";
import { DataTableCarnes } from "../MainContent";
import { useSearchParams } from 'react-router-dom';
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";
import { useIsMobile } from "../../utils/utils";
import {
  Descript,
} from "../../context/AuthProvider/util";
import { api } from "../../services/api";

const Integracao = () => 
{
  const isMobile = useIsMobile();
  const { activeSection, setActiveMenu, handleSideBar, toggleSubMenu, setIsDrawerOpen } = useHandleSideBar();
  const [searchParams] = useSearchParams();
  useEffect(() => {
   
    const token = searchParams.get("token"); 
    const rota = searchParams.get("rota"); 
    localStorage.setItem("token" , token ?? "" );

    descriptTokenUserIntegration(token??"");
    setActiveMenu(activeSection);
    toggleSubMenu(true);

    if (isMobile) {
      setIsDrawerOpen(true);
    }

   switch(rota){
     case "pagarparcela":
      return handleSideBar("Pagar Parcelas");
       

     case "consultarcarnes":
      return handleSideBar("Consultar Carnês");

     case "resgatar":
      return handleSideBar("Resgatar Produtos");

      case "cadastrarcarne":
      return handleSideBar("Cadastrar Carnê");
   }

  }, []);



  const descriptTokenUserIntegration = async (token:string) => {
      const user = await Descript(token).then((user) => {
        const userLogin = user.login;

        const userKey = {
          id: user.id,
          nome: user.nome,
          carneQuitado: false,
        };
  
        localStorage.setItem("userId", JSON.stringify(userKey));
      })
    }
  

  return <MinhaConta />;
};

export default Integracao;
