import React, { useEffect, useRef } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from '../../context/AuthProvider/useAuth';

const IdleTimerContainer = () => {
  const auth = useAuth();

  const handleLogout = () => {

    const tokenRedirectMagento = localStorage.getItem("dataClient");

    var url = `${process.env.REACT_APP_API_BAU}/customer/account/logoff/token=${tokenRedirectMagento}`;

    // handleLogoffMagento(url)

    localStorage.removeItem("token");
    localStorage.removeItem("tokenId");
    localStorage.removeItem("userId");
    localStorage.removeItem("user");
    localStorage.removeItem("token_system");
    localStorage.removeItem("bannerUserClose");
    localStorage.removeItem("dataClient");
    localStorage.removeItem("selectedItems");
    window.open("/login", "_self");
    
    
  };

  const handleLogoffMagento = (item: string) => {
    window.open(`${item}`, '_blank')
  };

  const { start, reset, pause } = useIdleTimer({
    timeout: 30 * 60 * 1000, // 30 minutos
    onIdle: () => {
      if (auth?.isAuthenticated()) {
        handleLogout();
      }
    },
  });

  useEffect(() => {
    const onAction = () => {
      reset();
    };

    document.addEventListener('keydown', onAction);
    document.addEventListener('mousemove', onAction);

    return () => {
      document.removeEventListener('keydown', onAction);
      document.removeEventListener('mousemove', onAction);
    };
  }, [reset]);

  useEffect(() => {
    start();

    return () => {
      pause();
    };
  }, [start, pause]);

  useEffect(() => {
    if (auth?.isAuthenticated()) {
      reset();
    }
  }, [auth, reset]);

  return null;
};

export default IdleTimerContainer;