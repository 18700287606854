import React, { useEffect, useState } from "react";
import ContainerBox from "../../components/ContainerBox";
import {
  Typography,
  Grid,
  Box,
  CardContent,
  Paper,
  InputBase,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import AccordionList from "../../components/AccordionList";
import { question } from "../../utils/questions";
import Troubleshooting from "../../components/Troubleshooting";
import { useLocation } from "react-router-dom";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import FacebookIcon from "@mui/icons-material/Facebook";
import Carousel from "react-bootstrap/Carousel";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import { useIsMobile } from "../../utils/utils";
import { alpha, styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

interface Question {
  question: string;
  description: string;
}

const Input = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1),
  },
  "& .MuiTypography-root .MuiFormLabel-root .MuiInputLabel-root": {
    fontFamily: "Red Hat Display, sans-serif",
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    border: "2px solid #E5E5E5",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    padding: "10px 12px",
    heigth: "30px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    fontFamily: ["Red Hat Display, sans-serif"].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));

const PerguntasFrequentes = () => {
  const location = useLocation();
  const [filter, setFilter] = useState<Question[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  useEffect(() => {
    const id = location.state?.id;
    const searchString = id || "";
    handleSearch(searchString);
  }, [location.state]);

  const removeAccents = (str: string) => {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const handleSearch = (searchString: string) => {
    setFilter([]);
    const results: Question[] = [];

    const normalizedSearchString = removeAccents(searchString)
      .replace(/[^\w\s]/gi, "")
      .toLowerCase();

    for (let i = 0; i < question.length; i++) {
      const q = question[i];
      const normalizedQuestion = removeAccents(q.question)
        .replace(/[^\w\s]/gi, "")
        .toLowerCase();
      const normalizedDescription = removeAccents(q.description)
        .replace(/[^\w\s]/gi, "")
        .toLowerCase();

      if (
        normalizedQuestion.includes(normalizedSearchString) ||
        normalizedDescription.includes(normalizedSearchString)
      ) {
        results.push(q);
      }
    }
    setFilter(searchString === "" ? question : results);
  };

  const handleQuestion = () => {
    const id = searchTerm;
    navigate("/perguntas-frequentes", { state: { id } });
    setIsSearching(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isSearching) {
      event.preventDefault();
      setIsSearching(true);
      handleQuestion();
      setSearchTerm("");
    }
  };

  return (
    <div style={{ margin: `${isMobile ? "60px 0" : "172px 0"}` }}>
      <ContainerBox>
        {filter.length === 0 ? (
          <Troubleshooting />
        ) : (
          <>
            <Typography variant="h1">Perguntas Frequentes</Typography>
            <Typography
              variant="subtitle2"
              color="#000000"
              sx={{
                margin: "0 15px",
              }}
            >
              Aqui você resolve tudo de um jeito simples e rápido.
            </Typography>
            <Input
              inputProps={{ "aria-label": "search-input" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Qual é sua dúvida?"
              sx={{
                width: { xs: "350px", sm: "100%" },
                margin: "40px 15px",
                fontStyle: "italic",
              }}
            />
            <AccordionList arrays={filter} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle2"
                color="#1C1C7B"
                sx={{
                  fontSize: "20px",
                  fontWeight: 700,
                  margin: "20px 0",
                }}
              >
                Ainda precisa de ajuda?
              </Typography>
            </div>
            {isMobile ? (
              <CarouselArrows indicators={false} interval={null} variant="dark">
                <Carousel.Item>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper
                      elevation={2}
                      sx={{
                        width: "330px",
                        height: "289px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#1C1C7B"
                          sx={{
                            fontSize: "20px",
                            texctAlign: "center",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          WHATS APP
                        </Typography>
                        <Box
                          sx={{
                            width: "62px",
                            height: "62px",
                            background: "#F08C10",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            margin: "20px 0",
                          }}
                        >
                          <WhatsAppIcon sx={{ fontSize: 50 }} />
                        </Box>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "center" }}
                          >
                            Fale com WhatsApp da Jequiti/Baú da
                            Felicidade.&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?phone=5511953020985"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: "#F08C10",
                                fontWeight: 700,
                              }}
                            >
                              Clique aqui.
                            </a>
                          </Typography>
                        </CardContent>
                      </div>
                    </Paper>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper
                      elevation={3}
                      sx={{
                        width: "330px",
                        height: "289px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#1C1C7B"
                          sx={{
                            fontSize: "20px",
                            texctAlign: "center",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Facebook
                        </Typography>
                        <Box
                          sx={{
                            width: "62px",
                            height: "62px",
                            background: "#F08C10",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            margin: "20px 0",
                          }}
                        >
                          <FacebookIcon sx={{ fontSize: 50 }} />
                        </Box>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "center" }}
                          >
                            Converse com a gente pelas redes sociais. Acesse
                            nosso Facebook.&nbsp;
                            <a
                              href="https://web.facebook.com/baudafelicidadejequiti?_rdc=1&_rdr"
                              target="_blank"
                              rel="noreferrer"
                              style={{
                                color: "#F08C10",
                                fontWeight: 700,
                              }}
                            >
                              Clique aqui.
                            </a>
                          </Typography>
                        </CardContent>
                      </div>
                    </Paper>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Paper
                      elevation={3}
                      sx={{
                        width: "330px",
                        height: "289px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          color="#1C1C7B"
                          sx={{
                            fontSize: "20px",
                            texctAlign: "center",
                            fontWeight: 700,
                            textTransform: "uppercase",
                          }}
                        >
                          Postos de Distribuição
                        </Typography>
                        <Box
                          sx={{
                            width: "62px",
                            height: "62px",
                            background: "#F08C10",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#FFFFFF",
                            margin: "20px 0",
                          }}
                        >
                          <WhatsAppIcon sx={{ fontSize: 50 }} />
                        </Box>
                        <CardContent>
                          <Typography
                            variant="subtitle1"
                            sx={{ textAlign: "center" }}
                          >
                            Encontre um dos postos de distribuição mais próximo
                            a você.&nbsp;
                            <span
                              style={{
                                color: "#F08C10",
                                fontWeight: 700,
                              }}
                            >
                              Em construção.
                            </span>
                          </Typography>
                        </CardContent>
                      </div>
                    </Paper>
                  </div>
                </Carousel.Item>
              </CarouselArrows>
            ) : (
              <Grid
                container
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      width: "330px",
                      height: "289px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="#1C1C7B"
                        sx={{
                          fontSize: "20px",
                          texctAlign: "center",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        WHATS APP
                      </Typography>
                      <Box
                        sx={{
                          width: "62px",
                          height: "62px",
                          background: "#F08C10",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFFFFF",
                          margin: "20px 0",
                        }}
                      >
                        <WhatsAppIcon sx={{ fontSize: 50 }} />
                      </Box>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Fale com WhatsApp da Jequiti/Baú da Felicidade.&nbsp;
                          <a
                            href="https://api.whatsapp.com/send?phone=5511953020985"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "#F08C10",
                              fontWeight: 700,
                            }}
                          >
                            Clique aqui.
                          </a>
                        </Typography>
                      </CardContent>
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      width: "360px",
                      height: "289px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="#1C1C7B"
                        sx={{
                          fontSize: "20px",
                          texctAlign: "center",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        Facebook
                      </Typography>
                      <Box
                        sx={{
                          width: "62px",
                          height: "62px",
                          background: "#F08C10",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFFFFF",
                          margin: "20px 0",
                        }}
                      >
                        <FacebookIcon sx={{ fontSize: 50 }} />
                      </Box>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Converse com a gente pelas redes sociais. Acesse nosso
                          Facebook.&nbsp;
                          <a
                            href="https://web.facebook.com/baudafelicidadejequiti?_rdc=1&_rdr"
                            target="_blank"
                            rel="noreferrer"
                            style={{
                              color: "#F08C10",
                              fontWeight: 700,
                            }}
                          >
                            Clique aqui.
                          </a>
                        </Typography>
                      </CardContent>
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Paper
                    elevation={3}
                    sx={{
                      width: "360px",
                      height: "289px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        color="#1C1C7B"
                        sx={{
                          fontSize: "20px",
                          texctAlign: "center",
                          fontWeight: 700,
                          textTransform: "uppercase",
                        }}
                      >
                        POSTOS DE DISTRIBUIÇÃO
                      </Typography>
                      <Box
                        sx={{
                          width: "62px",
                          height: "62px",
                          background: "#F08C10",
                          borderRadius: "10px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#FFFFFF",
                          margin: "20px 0",
                        }}
                      >
                        <WhatsAppIcon sx={{ fontSize: 50 }} />
                      </Box>
                      <CardContent>
                        <Typography
                          variant="subtitle1"
                          sx={{ textAlign: "center" }}
                        >
                          Encontre um dos postos de distribuição mais próximo a
                          você.&nbsp;
                          <span
                            style={{
                              color: "#F08C10",
                              fontWeight: 700,
                            }}
                          >
                            Em construção.
                          </span>
                        </Typography>
                      </CardContent>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                margin: "60px 0",
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="subtitle1"
                  color="#1C1C7B"
                  sx={{
                    fontWeight: 700,
                  }}
                >
                  Sac
                </Typography>
                <Typography
                  variant="subtitle1"
                  color="#1C1C7B"
                  sx={{
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  0800 545 2121
                </Typography>
                <Typography variant="subtitle1" color="#1C1C7B">
                  De segunda a sábado: das 8:00 às 22:00
                  <br />
                  Domingos e feriados: das 9:00 às 21:00
                </Typography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                border: "1px solid #F08C10",
                transform: "matrix(1, 0, 0, -1, 0, 0)",
              }}
            />
          </>
        )}
      </ContainerBox>
    </div>
  );
};

export default PerguntasFrequentes;
