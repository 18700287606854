import React, { useState, FC, useRef, useEffect } from "react";
import {
  Grid,
  TextField,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Divider,
  CircularProgress,
} from "@mui/material";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import ContainerBox from "../../components/ContainerBox";
import {
  useIsMobile,
  validateCpf,
  formatCpf,
  validateDate,
  formatPastedDate,
} from "../../utils/utils";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useBackOffice } from "../../context/BackOfficeProvider/useBackOffice";
import { useNavigate } from "react-router-dom";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

import { BuscaCliente } from "../../context/AuthProvider/util";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const Login: FC<Props> = ({ alert, message, openAlert }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const backOffice = useBackOffice();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [formData, setFormData] = useState({
    cpf: "",
    senha: "",
    birthDate: "",
    showPassword: false,
  });
  const [errorCpf, setErrorCpf] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errorBirthDate, setErrorBirthDate] = useState(false);
  const { handleSideBar, toggleSubMenu, activeSection } = useHandleSideBar();

  useEffect(() => {
    backOffice?.isBackOfficeLogged()
      ? setIsDisabled(!formData.cpf || !formData.birthDate || errorBirthDate)
      : setIsDisabled(!formData.cpf || !formData.senha || errorCpf);
  }, [
    formData.cpf,
    formData.senha,
    formData.birthDate,
    errorCpf,
    errorBirthDate,
    backOffice,
  ]);

  const buscaCliente = async (cpf: string) => {
    const response = await BuscaCliente(cpf);
    localStorage.setItem("invalidData", "");

    var contatoEmail = response.data.contatos.some(
      (contato: { tipo: number }) => contato.tipo === 1
    );

    if (contatoEmail === false) {
      localStorage.setItem("invalidData", "invalidEmail");
    } else {
      response.data.contatos
        .filter((contato: { tipo: number }) => contato.tipo === 1)
        .map((contatoEmail: any) => {
          if (contatoEmail.valor === null || contatoEmail.valor === "")
            localStorage.setItem("invalidData", "invalidEmail");
        });
    }

    if (response.data.nascimento === null || response.data.nascimento === "") {
      const dado = localStorage.getItem("invalidData");

      if (dado === "")
        localStorage.setItem("invalidData", "invalidDataNascimento");
      else localStorage.setItem("invalidData", "invalidEmailAndDataNascimento");
    } else {
      const year = new Date(response.data.nascimento).getFullYear();
      if (year <= 1920) {
        localStorage.setItem("invalidData", "invalidDataNascimento");

        if (contatoEmail === false && year <= 1920) {
          localStorage.setItem("invalidData", "invalidEmailAndDataNascimento");
        }

        if (contatoEmail && year <= 1920) {
          response.data.contatos
            .filter((contato: { tipo: number }) => contato.tipo === 1)
            .map((contatoEmail: any) => {
              if (contatoEmail.valor === null || contatoEmail.valor === "")
                localStorage.setItem(
                  "invalidData",
                  "invalidEmailAndDataNascimento"
                );
            });
        }
      }
    }
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    if (name === "birthDate") {
      setErrorBirthDate(!validateDate(value) || value === "");
    } else {
      setErrorCpf(!value);
    }
  };

  const handleChangeCpf = (event: any) => {
    const { name, value } = event.target;
    const unformattedValue = removeCpfFormatting(value);
    setFormData({ ...formData, [name]: unformattedValue });
    setErrorCpf(!validateCpf(event.target.value) || !unformattedValue);
  };

  const handleClickShowPassword = () => {
    setFormData({ ...formData, showPassword: !formData.showPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const removeCpfFormatting = (cpf: string) => {
    return cpf.replace(/\D/g, "");
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    try {
      if (backOffice?.isBackOfficeLogged()) {
        const response = await backOffice?.loginClient(
          formData.cpf,
          formData.birthDate
        );
      } else {
        const response = await auth?.login(formData.cpf, formData.senha);
      }
      setLoading(false);
      message("Login efetuado com sucesso!");

      buscaCliente(formData.cpf).then((result) => {
        alert("success");
        openAlert(true);

        // backOffice?.isBackOfficeLogged()
        //   ? navigate("/autenticar/minha-conta")

        //   : navigate("/minha-conta");

        backOffice?.isBackOfficeLogged()
          ? navigate("/autenticar/minha-conta")
          : navigate("/minha-conta");
      });
    } catch (error: any) {
      setLoading(false);
      message(JSON.stringify(error));
      alert("error");
      openAlert(true);
      localStorage.removeItem("token_system");
    }
  };

  const handleRegister = () => {
    backOffice?.isBackOfficeLogged()
      ? navigate("/autenticar/cadastro")
      : navigate("/cadastro");
  };

  const maxBirthdate = new Date();
  maxBirthdate.setFullYear(maxBirthdate.getFullYear() - 18);

  const handlePaste = (event: any, field: string) => {
    event.preventDefault();

    const cursorPosition = inputRef.current!.selectionStart;
    const pastedValue = event.clipboardData.getData("Text");

    if (field === "birthDate") {
      const formattedValue = formatPastedDate(pastedValue, cursorPosition);
      setFormData({
        ...formData,
        birthDate: formattedValue,
      });
    } else if (field === "cpf") {
      const unformattedValue = removeCpfFormatting(pastedValue);
      setFormData({
        ...formData,
        cpf: unformattedValue,
      });
    }
  };

  return (
    <div style={{ margin: `${isMobile ? "30px 0" : "155px 0"}` }}>
      <ContainerBox>
        <Grid
          container
          sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "center" },
              marginBottom: `${isMobile ? 10 : 98}`,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: `${isMobile ? "300px" : "454px"}`,
              }}
            >
              <Typography
                variant="h1"
                sx={{
                  width: "auto",
                }}
              >
                {`${
                  backOffice?.isBackOfficeLogged()
                    ? "Autenticar - área para atendimento"
                    : "Entre com seu CPF e senha para acessar sua conta"
                }`}
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{
                  width: "auto",
                }}
              >
                {`${
                  backOffice?.isBackOfficeLogged()
                    ? "Entre com o CPF e data de nascimento do cliente."
                    : `Para realizar pagamentos de parcelas, Cadastrar Carnê ou
                 resgatar produtos é necessário acessar sua conta ou fazer seu
                 cadastro!`
                }`}
              </Typography>
              <form onSubmit={handleSubmit}>
                <div>
                  <Typography
                    variant="subtitle2"
                    color="#000000"
                    sx={{
                      textTransform: "uppercase",
                      marginTop: "20px",
                      fontSize: "10px",
                      fontWeight: 700,
                    }}
                  >
                    CPF
                  </Typography>
                  <TextField
                    sx={{
                      boxSizing: "border-box",
                      background: "rgba(255, 255, 255, 0.1)",
                      borderRadius: "4px",
                      width: "100%",
                    }}
                    name="cpf"
                    ref={inputRef}
                    value={formatCpf(formData.cpf)}
                    onChange={handleChangeCpf}
                    onPaste={(event) => handlePaste(event, "cpf")}
                    error={errorCpf}
                    helperText={errorCpf ? "CPF inválido" : null}
                    placeholder="000.000.000-00"
                    required
                    InputProps={{
                      endAdornment:
                        errorCpf || formData.cpf === "" ? null : (
                          <CheckOutlinedIcon
                            sx={{ color: "orange", marginRight: "8px" }}
                          />
                        ),
                      style: {
                        height: "48px",
                      },
                    }}
                  />
                </div>
                <div>
                  {!backOffice?.isBackOfficeLogged() ? (
                    <>
                      <Typography
                        variant="subtitle2"
                        color="#000000"
                        sx={{
                          textTransform: "uppercase",
                          marginTop: "20px",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        Senha
                      </Typography>
                      <TextField
                        sx={{
                          boxSizing: "border-box",
                          background: "#FFFFFF",
                          border: "1px solid #BDBDBD",
                          borderRadius: "4px",
                          width: "100%",
                        }}
                        name="senha"
                        type={formData.showPassword ? "text" : "password"}
                        required
                        value={formData.senha}
                        onChange={handleChange}
                        InputProps={{
                          style: {
                            height: "48px",
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {formData.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Typography
                        variant="subtitle2"
                        component="a"
                        color="#1C1C7B"
                        href="/recuperar-senha"
                        sx={{
                          textTransform: "uppercase",
                          textDecorationLine: "underline",
                          fontSize: "10px",
                          fontWeight: 700,
                          cursor: "pointer",
                        }}
                      >
                        esqueci minha senha
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="subtitle2"
                        color="#000000"
                        sx={{
                          textTransform: "uppercase",
                          marginTop: "20px",
                          fontSize: "10px",
                          fontWeight: 700,
                        }}
                      >
                        data de nascimento*
                      </Typography>
                      <TextField
                        inputRef={inputRef}
                        sx={{
                          boxSizing: "border-box",
                          background: "rgba(255, 255, 255, 0.1)",
                          borderRadius: "4px",
                          width: "100%",
                        }}
                        name="birthDate"
                        type="date"
                        value={formData.birthDate}
                        onChange={handleChange}
                        onPaste={(event) => handlePaste(event, "birthDate")}
                        placeholder="00/00/0000"
                        required
                        error={errorBirthDate}
                        helperText={
                          errorBirthDate
                            ? "Informe uma data de nascimento válida e anterior à data atual"
                            : ""
                        }
                        InputProps={{
                          style: {
                            height: "48px",
                          },
                        }}
                        inputProps={{
                          pattern: "[^0-9]*",
                          readOnly: false,
                          min: new Date("1900-01-01")
                            .toISOString()
                            .split("T")[0],
                          max: new Date().toISOString().split("T")[0],
                          maxLength: 10,
                        }}
                      />
                    </>
                  )}
                </div>
                <Button
                  disabled={isDisabled || loading}
                  type="submit"
                  sx={{
                    width: "100%",
                    height: "48px",
                    marginTop: "30px",
                    fontSize: "14px",
                    color: "#FFFF",
                    background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                    "&: hover": {
                      background: `${isDisabled ? "#D1D1D1" : "#F08C10"}`,
                    },
                  }}
                >
                  {loading ? (
                    <CircularProgress sx={{ color: "#FFFFFF" }} />
                  ) : (
                    "entrar"
                  )}
                </Button>
              </form>
            </div>
          </Grid>
          <Grid
            item
            lg={6}
            md={6}
            sm={12}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: {
                xs: "center",
                sm: "center",
              },
              marginBottom: `${isMobile ? 10 : 98}`,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "column",
                width: `${isMobile ? "300px" : "454px"}`,
              }}
            >
              <Divider
                variant="inset"
                sx={{
                  opacity: "0.5",
                  border: "1px solid #BDBDBD",
                  width: 1,
                  "@media (max-width: 898px)": {
                    margin: "60px 0",
                  },
                  "@media (min-width: 899px) and (max-width: 1199px)": {
                    transform: "rotate(90deg)",
                    marginLeft: -27,
                  },
                  "@media (min-width: 1200px)": {
                    transform: "rotate(90deg)",
                    marginLeft: -35,
                    width: 1,
                  },
                }}
              />

              <Typography variant="h2">
                {`${
                  backOffice?.isBackOfficeLogged()
                    ? "Primeiro acesso"
                    : "Sou novo por aqui? Quero fazer meu cadastro!"
                }`}
              </Typography>
              <Typography
                variant="subtitle1"
                color="#000000"
                sx={{
                  margin: {
                    xs: "10px 0 15px 0",
                    sm: "10px 0 40px 0",
                    md: "10px 0 50px 0",
                    lg: "10px 0px 90px 0px",
                  },
                }}
              >
                {`${
                  backOffice?.isBackOfficeLogged()
                    ? "Para realizar o cadastro de um novo cliente, clique abaixo"
                    : `Precisamos de poucas informações para criar sua conta no Baú da
                Felicidade.`
                }`}
              </Typography>
              <Button
                onClick={handleRegister}
                sx={{
                  width: "100%",
                  height: "48px",
                  margin: {
                    xs: "30px 0 0 0",
                    sm: "40px 0 0 0",
                    // md: "97px  0",
                  },
                  fontSize: "14px",
                  color: "#FFFFFF",
                  background: "#F08C10",
                  "&: hover": {
                    background: "#F08C10",
                  },
                }}
              >
                criar conta
              </Button>
            </div>
          </Grid>
        </Grid>
      </ContainerBox>
    </div>
  );
};

export default Login;
