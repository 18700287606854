import { Typography, Grid, Divider, Box, CardContent } from "@mui/material";
import ContainerBox from "../../components/ContainerBox";
import { styles } from "../../styles";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";
import { useIsMobile } from "../../utils/utils";

const FaleConosco = () => {
  const isMobile = useIsMobile();

  return (
    <ContainerBox sx={{ marginTop: { sm: "83px", md: "172px", lg: "172px" } }}>
      <Typography variant="h1" sx={{ margin: 0 }}>
        Fale Conosco
      </Typography>
      <Typography
        variant="subtitle1"
        color="#000000"
        sx={{
          lineHeight: "22px",
          marginTop: "18px",
        }}
      >
        Canais de atendimento
      </Typography>
      <Grid
        container
        sx={{ display: "flex", justifyContent: "center", margin: "35px 0 0 0" }}
      >
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle1"
              color="#1C1C7B"
              sx={{
                fontSize: "20px",
                texctAlign: "center",
                fontWeight: 700,
              }}
            >
              WhatsApp
            </Typography>
            <Box
              sx={{
                width: "62px",
                height: "62px",
                background: "#25D366",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFFFFF",
                margin: "20px 0",
              }}
            >
              <WhatsAppIcon sx={{ fontSize: 50 }} />
            </Box>
            <CardContent>
              <Typography
                variant="subtitle1"
                color="#1C1C7B"
                sx={{
                  textAlign: "center",
                }}
              >
                Fale com WhatsApp da Jequiti/Baú da Felicidade.&nbsp;
                <a
                  href="https://api.whatsapp.com/send?phone=5511953020985"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "#1C1C7B",
                    fontWeight: 700,
                  }}
                >
                  Clique aqui.
                </a>
              </Typography>
            </CardContent>
          </div>
        </Grid>
        {!isMobile && (
          <Grid
            item
            lg={3}
            md={3}
            sm={12}
            xs={12}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <Divider
              variant="fullWidth"
              flexItem
              sx={{
                border: "1px solid #BDBDBD",

                "@media (max-width: 1199px)": {
                  width: "350px",
                  height: "1px",
                  margin: "32px",
                },
              }}
            />
          </Grid>
        )}
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="subtitle1"
              color="#1C1C7B"
              sx={{
                fontSize: "20px",
                texctAlign: "center",
                fontWeight: 700,
                textTransform: "uppercase",
                marginTop: `${isMobile ? "15px" : "0"}`,
              }}
            >
              telefone
            </Typography>
            <Box
              sx={{
                width: "62px",
                height: "62px",
                background: "#1C1C7B",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#FFFFFF",
                margin: "20px 0",
              }}
            >
              <CallIcon sx={{ fontSize: 50 }} />
            </Box>
            <Typography
              variant="subtitle1"
              color="#1C1C7B"
              sx={{
                fontSize: "20px",
                texctAlign: "center",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              sac
            </Typography>
            <Typography
              variant="subtitle1"
              color="#1C1C7B"
              sx={{
                fontSize: "20px",
                texctAlign: "center",
                fontWeight: 700,
                textTransform: "uppercase",
              }}
            >
              0800 545 2121
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{ textAlign: "center", width: "350px" }}
            >
              De segunda a sábado: das 8:00 às 22:00
              <br />
              Domingos e feriados: das 9:00 às 21:00
            </Typography>
          </div>
        </Grid>
      </Grid>
    </ContainerBox>
  );
};

export default FaleConosco;
