import { api, apiToken } from '../../services/api';
import axios from 'axios';

export const LoginRequest = async (login: string, senha: string) => {

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  try {
    const request = await authorization.post('api/v01/login/autenticar', { usuario: login, senha: senha });
    return request.data.token;

  } catch (error: any) {
    throw error.response.data;
  }
}

export const LoginClient = async (login: string, date: string) => {
  // const response = await apiToken();

  const authorization = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'cpf-cliente': `${login}`,
      'Content-Type': 'application/json',
    },
  });

  try {
    const request = await authorization.get(`api/v01/cliente/valida-cliente/${login}/${date}`);
    return request.data;

  } catch (error: any) {
    throw error.response.data.message;
  }
}

export const PaymentPix = async (data: any, tokenUser: any, login: string) => {
  // const response = await apiToken();

  const request = axios.create({
    baseURL: `${process.env.REACT_APP_API}`,
    headers: {
      'Authorization': `Bearer ${process.env.REACT_APP_TOKEN_ACCESS}`,
      'Content-Type': 'application/json',
      'token-user': `Bearer ${tokenUser}`,
      'cpf-cliente': `${login.replace(/\D/g, "")}`
    },
  });

  try {
    const response = await request.post(`api/v01/pagamento/pix/pagar`, JSON.stringify(data));
    return response.data;
  } catch (error: any) {
    throw error.response.data;
  }
}