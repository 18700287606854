import React, { useContext, useState, FC } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Divider,
  Collapse,
  ListItemButton,
  ListItemText,
  Paper,
  InputBase,
  Drawer,
  Badge,
  Grid,
  List,
  SelectChangeEvent,
  Menu,
  MenuItem,
} from "@mui/material";
import PersonOutlineSharpIcon from "@mui/icons-material/PersonOutlineSharp";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ViewInArOutlinedIcon from "@mui/icons-material/ViewInArOutlined";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import ContainerBox from "../ContainerBox";
import { CartContext } from "../../context/CartProvider";
import MiniCart from "../../components/MiniCart";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { useAuth } from "../../context/AuthProvider/useAuth";
import { useHandleSideBar } from "../../context/MenuSideBar/useHandleSideBar";

type Props = {
  alert: React.Dispatch<React.SetStateAction<"success" | "error" | null>>;
  message: React.Dispatch<React.SetStateAction<string>>;
  openAlert: React.Dispatch<React.SetStateAction<boolean>>;
};

const NavBarMobile: FC<Props> = ({ alert, message, openAlert }) => {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth?.user;
  const [open, setOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [login, setlogin] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const { cartItems, setCartItems, updateCartFromLocalStorage } =
    useContext(CartContext)!;
  const [isOpen, setIsOpen] = useState(false);
  const [quantity, setQuantity] = useState("1");
  const { handleSideBar, toggleSubMenu, setActiveMenuItem, setIsDrawerOpen } =
    useHandleSideBar();

  const handleSearch = () => {
    const id = searchTerm;
    navigate("/perguntas-frequentes", { state: { id } });
    setIsSearching(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && !isSearching) {
      event.preventDefault();
      setIsSearching(true);
      handleSearch();
      setSearchTerm("");
    }
  };

  const StyledBadge = styled(Badge)({
    "& .MuiBadge-badge": {
      background: "#F08C10",
    },
  });

  const handleCarne = () => {
    setMenuOpen(!menuOpen);
  };

  const handleMiniCartOpen = () => {
    setIsOpen(true);
  };

  const handleMiniCartClose = () => {
    setIsOpen(false);
  };

  const removeItem = (productId: number) => {
    const updatedCart = cartItems.filter((item: any) => item.id !== productId);
    setCartItems(updatedCart);
    localStorage.setItem("selectedItems", JSON.stringify(updatedCart));

    updateCartFromLocalStorage();
    message("Item removido com sucesso!");
    alert("success");
    openAlert(true);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setQuantity(event.target.value);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpenMenu(true);
    setIsDrawerOpen(false);
  };

  const handleLogout = () => {
    auth?.logout();
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: "0 1px",
  }));

  const handleNavButtonClick = (buttonName: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "header",
      click_text: buttonName,
    });
  };

  const handleClick = (nameMenuItem: string) => {
    handleNavButtonClick(nameMenuItem);
    navigate("/minha-conta");
    setActiveMenuItem(nameMenuItem);
    handleSideBar(nameMenuItem);
    handleNavButtonClick(nameMenuItem);
    setOpenMenu(false);
  };

  const menuNav = [
    {
      name: "Pagar Parcelas",
      url: () => handleSideBar("Pagar Parcelas"),
    },
    {
      name: "Cadastrar Carnê",
      url: () => {
        handleSideBar("Cadastrar Carnê");
      },
    },
    {
      name: "Resgatar Produtos",
      url: () => {
        handleSideBar("Resgatar Produtos");
      },
    },
  ];

  const handleLogin = () => {
    setOpenMenu(false);
    navigate("/login");
  };

  const handleMenuClick = (itemMenu: any) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "button_click",
      click_category: "header",
      click_text: itemMenu,
    });
    if (itemMenu === "carne digital") {
      window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2024-digital.html`;
    } else if (itemMenu === "carne fisico") {
      window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti.html`;
    }
    // const tokenRedirectMagento = localStorage.getItem("dataClient");
    // if (tokenRedirectMagento !== null && tokenRedirectMagento !== "") {
    //   if (itemMenu === "carne digital") {
    //     window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-digital.html?token=${tokenRedirectMagento}`;
    //   } else if (itemMenu === "carne fisico") {
    //     window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2022.html?token=${tokenRedirectMagento}`;
    //   }
    // } else {
    //   if (itemMenu === "carne digital") {
    //     window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-digital.html`;
    //   } else if (itemMenu === "carne fisico") {
    //     window.location.href = `${process.env.REACT_APP_API_BAU}/carne-do-bau-da-felicidade-jequiti-2022.html`;
    //   }
    // }
  };

  return (
    <>
      <MiniCart
        open={isOpen}
        arrCart={cartItems}
        remove={removeItem}
        quantity={quantity}
        change={handleChange}
        changeClose={handleMiniCartClose}
      />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          sx={{
            height: "56px",
            backgroundColor: "#1c1c7b",
          }}
        >
          <Toolbar>
            <Grid container>
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  padding: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => handleDrawerOpen()}
                >
                  <MenuIcon />
                </IconButton>
                <IconButton
                  sx={{ padding: "0" }}
                  size="large"
                  aria-label={"search"}
                  color="inherit"
                >
                  <SearchIcon
                    sx={{
                      fontSize: 30,
                    }}
                  />
                </IconButton>
              </Grid>
              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography component="a" href="/">
                  <img
                    src={`${process.env.REACT_APP_IMAGE_SERVER_URL}logoJequiti.svg`}
                    alt="logo-jequiti"
                    style={{
                      position: "absolute",
                      top: "80%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      maxWidth: "100%",
                      height: "auto",
                    }}
                  />
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                sm={2}
                sx={{
                  padding: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <PopupState variant="popover" popupId="popup-login">
                  {(popupLogin) => (
                    <React.Fragment>
                      <IconButton color="inherit">
                        <PersonOutlineOutlinedIcon
                          {...bindTrigger(popupLogin)}
                          sx={{
                            fontSize: 35,
                          }}
                        />
                      </IconButton>
                      <Menu
                        {...bindMenu(popupLogin)}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            backgroundColor: "#F08C10",
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              backgroundColor: "#F08C10",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{
                          horizontal: "right",
                          vertical: "top",
                        }}
                        anchorOrigin={{
                          horizontal: "right",
                          vertical: "bottom",
                        }}
                      >
                        {auth?.isAuthenticated() ? (
                          <>
                            <MenuItem onClick={popupLogin.close}>
                              <Typography
                                component="a"
                                href={"/minha-conta"}
                                variant="subtitle2"
                                color="#FFFFFF"
                                sx={{
                                  textDecoration: "none",
                                  "&:hover": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                Minha Conta
                              </Typography>
                            </MenuItem>
                            <MenuItem onClick={popupLogin.close}>
                              <Typography
                                component="a"
                                onClick={handleLogout}
                                variant="subtitle2"
                                color="#FFFFFF"
                                sx={{
                                  textDecoration: "none",
                                  "&:hover": {
                                    color: "#FFFFFF",
                                  },
                                }}
                              >
                                Sair
                              </Typography>
                            </MenuItem>
                          </>
                        ) : (
                          <MenuItem onClick={popupLogin.close}>
                            <Typography
                              component="a"
                              href={"/login"}
                              variant="subtitle2"
                              color="#FFFFFF"
                              sx={{
                                textDecoration: "none",
                                "&:hover": {
                                  color: "#FFFFFF",
                                },
                              }}
                            >
                              Faça <strong>login </strong>e aproveite todos
                              <br /> os benefícios do Baú.
                            </Typography>
                          </MenuItem>
                        )}
                      </Menu>
                    </React.Fragment>
                  )}
                </PopupState>
                <IconButton
                  size="large"
                  aria-label={"sacola"}
                  color="inherit"
                  key={"2"}
                  onClick={handleMiniCartOpen}
                  sx={{ padding: 1 }}
                >
                  <StyledBadge
                    badgeContent={cartItems.length}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <ShoppingCartOutlinedIcon sx={{ fontSize: 30 }} />
                  </StyledBadge>
                </IconButton>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <ContainerBox
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Paper
            component="form"
            sx={{
              display: "flex",
              justifyContente: "center",
              alignItems: "center",
              width: "90%",
              height: 32,
              margin: {
                xs: "25px 10px 0px 10px",
                sm: "35px 10px 0px 10px",
              },
              background: "rgba(0, 0, 0, 0.1)",
            }}
          >
            <InputBase
              sx={{
                ml: 1,
                flex: 1,
                fontStyle: "italic",
                color: "#1C1C7B",
                fontWeight: 700,
              }}
              placeholder="O que você procura?"
              inputProps={{ "aria-label": "search-input" }}
              value={searchTerm}
              onChange={(e: {
                target: { value: React.SetStateAction<string> };
              }) => setSearchTerm(e.target.value)}
              onKeyPress={handleKeyPress}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon sx={{ color: "#1C1C7B" }} />
            </IconButton>
          </Paper>
        </ContainerBox>
        <Drawer
          sx={{
            width: "90%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "90%",
              boxSizing: "border-box",
              backgroundColor: "#1c1c7b",
            },
          }}
          variant="persistent"
          anchor="left"
          open={openMenu}
        >
          <DrawerHeader style={{ justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => setOpenMenu(false)}
              style={{ color: "#FFFFFF", margin: "40px 0 20px 0" }}
            >
              <CloseIcon />
            </IconButton>
          </DrawerHeader>
          {auth?.isAuthenticated() ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <List sx={{ width: "100%", maxWidth: 360, color: "#FFFFFF" }}>
                <ListItemText>
                  <Typography
                    variant="h2"
                    color="#FFFFFF"
                    component="a"
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textDecoration: "none",
                      margin: "0 0 35px 20px",
                      fontWeight: 500,
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Olá, {user?.nome}
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography
                    variant="h2"
                    color="#FFFFFF"
                    component="a"
                    onClick={() => handleClick("Meus Pedidos")}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textDecoration: "none",
                      margin: "0 0 35px 20px",
                      fontWeight: 500,
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <ViewInArOutlinedIcon sx={{ marginRight: "15px" }} /> Meus
                    Pedidos
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography
                    variant="h2"
                    color="#FFFFFF"
                    component="a"
                    onClick={() => handleClick("Meus Dados")}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textDecoration: "none",
                      margin: "0 0 35px 20px",
                      fontWeight: 500,
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <PersonOutlineSharpIcon sx={{ marginRight: "15px" }} /> Meus
                    Dados
                  </Typography>
                </ListItemText>
                <ListItemText>
                  <Typography
                    variant="h2"
                    color="#FFFFFF"
                    component="a"
                    onClick={() => handleClick("Consultar Carnês")}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textDecoration: "none",
                      margin: "0 0 35px 20px",
                      fontWeight: 500,
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <FileCopyOutlinedIcon sx={{ marginRight: "15px" }} />{" "}
                    Consultar Carnês
                  </Typography>
                </ListItemText>
              </List>
            </div>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  variant="subtitle1"
                  color="#FFFFFF"
                  component="p"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "20px",
                    fontWeight: 700,
                    fontSize: "20px",
                  }}
                >
                  <PersonOutlineSharpIcon
                    sx={{ fontSize: 30, margin: "0 15px" }}
                  />
                  <span style={{ width: "250px" }}>
                    Faça seu Login e melhore ainda mais sua experiência
                  </span>
                </Typography>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flexDirection: "column" }}>
                  <Typography
                    variant="button"
                    color="#FFFFFF"
                    component="button"
                    onClick={() => handleLogin()}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "15px",
                      width: "264px",
                      height: "48px",
                      fontSize: "14px",
                      border: "none",
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    login
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="#FFFFFF"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Não possui uma conta?&nbsp;&nbsp;&nbsp;
                    <a
                      href="/cadastro"
                      style={{
                        color: "#F08C10",
                        textDecoration: "none",
                        fontWeight: 700,
                      }}
                    >
                      Cadastrar-se{" "}
                      <ArrowForwardIosSharpIcon sx={{ fontSize: 15 }} />
                    </a>
                  </Typography>
                </div>
              </div>
            </>
          )}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Divider
              variant="middle"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "1px solid #FFFFFF",
                margin: "30px 0",
                width: "300px",
              }}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <List sx={{ width: "100%", maxWidth: 360, color: "#FFFFFF" }}>
              <ListItemText>
                <Typography
                  variant="h2"
                  component="span"
                  onClick={() => handleMenuClick("carne fisico")}
                  color="#FFFFFF"
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    alignItems: "center",
                    textDecoration: "none",
                    margin: "0 0 15px 20px",
                    fontWeight: 500,
                    "&: hover": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  Comprar Carnê
                </Typography>
              </ListItemText>
            </List>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <List sx={{ width: "100%", maxWidth: 360, color: "#FFFFFF" }}>
              <ListItemButton onClick={handleCarne}>
                <ListItemText>
                  <Typography
                    variant="h2"
                    component="span"
                    color="#FFFFFF"
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      textDecoration: "none",
                      margin: "0 0 15px 20px",
                      fontWeight: 500,
                      "&: hover": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    Comprar Carnê
                  </Typography>
                </ListItemText>
                {menuOpen ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                <List
                  sx={{
                    width: "100%",
                    maxWidth: 360,
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    color="#FFFFFF"
                    component="span"
                    onClick={() => handleMenuClick("carne fisico")}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "0 25px",
                      textDecoration: "none",
                    }}
                  >
                    Carnê Físico
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="#FFFFFF"
                    component="span"
                    onClick={() => handleMenuClick("carne digital")}
                    sx={{
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      margin: "20px 25px",
                      textDecoration: "none",
                    }}
                  >
                    Carnê Digital
                  </Typography>
                </List>
              </Collapse>
            </List>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Divider
              variant="middle"
              sx={{
                display: "flex",
                justifyContent: "center",
                border: "1px solid #FFFFFF",
                margin: "15px 0",
                width: "300px",
              }}
            />
          </div>
          {auth?.isAuthenticated() && (
            <div
              style={{
                display: "flex",
                justifyContent: "start",
                margin: "10px 0px 0px 10px ",
              }}
            >
              <Typography
                variant="h2"
                color="#F08C10"
                component="button"
                onClick={() => auth?.logout()}
                sx={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                  textDecoration: "none",
                  fontWeight: 400,
                  background: "#1c1c7b",
                  border: "none",
                }}
              >
                <LogoutOutlinedIcon sx={{ marginRight: "10px" }} /> Sair
              </Typography>
            </div>
          )}
        </Drawer>
      </Box>
    </>
  );
};

export default NavBarMobile;
