import ContainerBox from "../ContainerBox";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useIsMobile } from "../../utils/utils";
import { depoimento } from "../../utils/data";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import styled from "styled-components";
import Carousel from "react-bootstrap/Carousel";
import { CarouselArrows } from "../../components/CarouselArrows/CarouselArrows";
import { useTheme } from "@mui/material/styles";
import {
  AwardValue,
  CircleContainer,
  Container,
  ContainerImg,
} from "./CardDepoimento.styled";

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardDepoimento = () => {
  const isMobile = useIsMobile();
  const theme = useTheme();

  const cards = [];
  for (let i = 0; i < depoimento.length; i += 4) {
    const row = [];
    for (let j = i; j < i + 4 && j < depoimento.length; j++) {
      row.push(
        <ContainerImg>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircleContainer>
              <CardMedia
                component="img"
                image={depoimento[j].image}
                alt=""
                sx={{
                  width: "100px",
                  height: "100px",
                  borderRadius: "50%",
                  marginTop: "-6px",
                }}
              />
            </CircleContainer>
          </div>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography
                variant="subtitle2"
                sx={{
                  fontWeight: 700,
                  textAlign: "center",
                  height: "10px",
                  marginBottom: "10px",
                }}
              >
                {depoimento[j].name}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#000000"
                sx={{ textAlign: "center" }}
              >
                {depoimento[j].city}
              </Typography>
              <Typography
                variant="subtitle2"
                color="#000000"
                sx={{
                  textAlign: "center",
                  fontStyle: "italic",
                  margin: "10px 0",
                  height: "20px",
                }}
              >
                {depoimento[j].text}
              </Typography>
            </div>
          </CardContent>
          <CardContent
            sx={{
              minHeight: 19,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <AwardValue>
              <Typography
                className="value"
                variant="subtitle2"
                fontWeight={theme.typography.fontWeightBold}
                color="#F08C10"
                sx={{
                  textTransform: "uppercase",
                }}
              >
                Ganhou
              </Typography>
              <Typography
                className="value"
                variant="subtitle2"
                color="#F08C10"
                fontWeight={theme.typography.fontWeightBold}
                sx={{
                  fontFamily: "Red Hat Display",
                  fontWeight: 900,
                  fontSize: 30,
                }}
              >
                {depoimento[j].value}
              </Typography>
            </AwardValue>
          </CardContent>
        </ContainerImg>
      );
    }
    cards.push(
      <Carousel.Item key={i}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {row}
        </div>
      </Carousel.Item>
    );
  }

  return (
    <ContainerBox sx={{ margin: "30px 0px 20px 0px" }}>
      <Typography
        variant="h1"
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        Depoimento de quem já ganhou
      </Typography>
      {isMobile ? (
        <CarouselArrows indicators={false} interval={null} variant="dark">
          {depoimento.map((item: any, key: any) => (
            <Carousel.Item key={key}>
              <CardsWrapper>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ContainerImg>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <CircleContainer>
                        <CardMedia
                          component="img"
                          image={item.image}
                          alt=""
                          sx={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            marginTop: "-6px",
                          }}
                        />
                      </CircleContainer>
                    </div>
                    <CardContent
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 700,
                            textAlign: "center",
                            height: "10px",
                            marginBottom: "10px",
                          }}
                        >
                          {item.name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="#000000"
                          sx={{ textAlign: "center" }}
                        >
                          {item.city}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          color="#000000"
                          sx={{
                            textAlign: "center",
                            fontStyle: "italic",
                            margin: "10px 0",
                            height: "20px",
                          }}
                        >
                          {item.text}
                        </Typography>
                      </div>
                    </CardContent>
                    <CardContent
                      sx={{
                        minHeight: 19,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <AwardValue>
                        <Typography
                          className="value"
                          variant="subtitle2"
                          fontWeight={theme.typography.fontWeightBold}
                          color="#F08C10"
                          sx={{
                            textTransform: "uppercase",
                          }}
                        >
                          Ganhou
                        </Typography>
                        <Typography
                          className="value"
                          variant="subtitle2"
                          color="#F08C10"
                          fontWeight={theme.typography.fontWeightBold}
                          sx={{
                            fontFamily: "Red Hat Display",
                            fontWeight: 900,
                            fontSize: 30,
                          }}
                        >
                          {item.value}
                        </Typography>
                      </AwardValue>
                    </CardContent>
                  </ContainerImg>
                </div>
              </CardsWrapper>
            </Carousel.Item>
          ))}
        </CarouselArrows>
      ) : (
        <Container>
          <CarouselArrows
            indicators={false}
            interval={null}
            variant="dark"
            className="carousel"
          >
            {cards}
          </CarouselArrows>
        </Container>
      )}
    </ContainerBox>
  );
};

export default CardDepoimento;
